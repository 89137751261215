import React from 'react'
import PageTemplate from './pageTemplate'

export default function MarkdownPageTemplate({ pageContext }) {
  const { markdownRemark } = pageContext
  const title = markdownRemark.frontmatter.title
  return (
    <PageTemplate title={title}>
      <div dangerouslySetInnerHTML={{ __html: markdownRemark.html }} />
    </PageTemplate>
  )
}
